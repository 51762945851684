<template>
  <div id="equipment">
    <!-- <div class="wrapper">
      <p class="route-title">设备管理</p> -->

    <!-- <div class="add"
           @click="addUavModal = !addUavModal">
        添加设备
      </div> -->

    <div class="all_navBar" v-if="$CSTJ">
      <router-link v-for="(item, ind) in all_navBar" :key="ind" :to="item.path">
        <!-- <div :class="SelectedInd != ind ? 'Selected' : ''"
             @click="Selected(ind)">
          <img :src="SelectedInd != ind ? item.img_press : item.img"
               :style="{width:(ind==2?'10px':'15px')}"
               alt="" />
          {{ item.name }}
        </div> -->

        <span
          :id="SelectedInd == ind ? 'Selected' : ''"
          @click="Selected(ind)"
          >{{ item.name }}</span
        >
      </router-link>
    </div>

    <!-- </div> -->

    <div class="conent">
      <router-view :teamID="teamID"></router-view>
    </div>
  </div>
</template>

<script>
import { DeviceList, DeviceType, DeviceAdd } from "@/utils/javaApi.js";
export default {
  name: "equipment",

  data: function () {
    return {
      teamID: -1, // 团队id
      userType: "",
      userInfo: {},
      all_navBar: [
        {
          name: "无人机管理",
          path: "/index/equipment/UavManage",
          img: require("@/assets/img/Icon/UavManage.png"),
          img_press: require("@/assets/img/Icon/UavManage_press.png"),
        },
        // {
        //   name: '电池管理',
        //   path: '/index/equipment/BatteryManage',
        //   img: require('@/assets/img/Icon/Battery.png'),
        //   img_press: require('@/assets/img/Icon/Battery_press.png'),
        // },
        // {
        //   name: '使用记录',
        //   path: '/index/equipment/UsageLog',
        // },
        // {
        //   name: '设备保养',
        //   path: '/index/equipment/EquipmentMaintenance',
        //   img: require('@/assets/img/Icon/equipment.png'),
        //   img_press: require('@/assets/img/Icon/equipment_press.png'),
        // },
        {
          name: "警用适航证",
          path: "/index/equipment/table",
        },
      ],
      SelectedInd: 0,
      addUavModal: false,
      addUav: {
        team_id: "",
        device_name: "",
        brand: "DJI",
        model: [],
        serial_code: "",
        flycontrol: "",
        uav_type: "",
        registration: "",
        is_insurance: "",
        insu_company: "",
        insu_no: "",
        buydate: "",
        validityday: "",
        insutype: "",
        sales_company: "",
      },
      addUav_ruleInline: {
        device_name: [
          { required: true, message: "请填写设备名称", trigger: "blur" },
        ],
        // brand: [
        //   { required: true, message: '请填写无人机品牌', trigger: 'blur' },
        // ],
        model: [
          {
            required: true,
            message: "请选择无人机型号",
            trigger: "change",
            pattern: /.+/,
          },
        ],
        serial_code: [
          { required: true, message: "请填写产品序列号", trigger: "blur" },
        ],
        flycontrol: [
          { required: true, message: "请填写飞控序列号", trigger: "blur" },
        ],
        uav_type: [
          {
            required: true,
            message: "请选择无人机类型",
            trigger: "change",
            pattern: /.+/,
          },
        ],
      },
      UAVTypeList: [],
      whether_insurance: "无保险",
    };
  },

  methods: {
    //选中nav
    Selected(ind) {
      //   this.$refs['teamCascader'].clear();
      //   this.teamID = -1;
      this.SelectedInd = ind;
    },
    addUav_ok() {
      this.$refs["addUav"].validate((valid) => {
        if (valid) {
          var data = {
            team_id: this.teamID == -1 ? this.userInfo.team_id : this.teamID,
            device_name: this.addUav.device_name,
            // brand: this.addUav.brand,
            class_id: this.addUav.model[1],
            serial_code: this.addUav.serial_code,
            flycontrol: this.addUav.flycontrol,
            uav_type: this.addUav.uav_type,
            registration: this.addUav.registration,
            is_insurance: this.whether_insurance == "有保险" ? 1 : 0,
          };
          // 如果有保险
          if (data.is_insurance == 1) {
            if (this.addUav.insu_company == "") {
              this.$Message.warning("保险公司不能为空");
              return;
            }
            if (this.addUav.insu_no == "") {
              this.$Message.warning("保险单号不能为空");
              return;
            }
            if (this.addUav.insutype == "") {
              this.$Message.warning("保险类型不能为空");
              return;
            }
            if (this.addUav.sales_company == "") {
              this.$Message.warning("保险销售公司不能为空");
              return;
            }
            data.insu_company = this.addUav.insu_company;
            data.insu_no = this.addUav.insu_no;
            data.buydate = this.$moment(this.addUav.buydate).format(
              "YYYY-MM-DD"
            );
            data.validityday = this.$moment(this.addUav.validityday).format(
              "YYYY-MM-DD"
            );

            data.insutype = this.addUav.insutype;
            data.sales_company = this.addUav.sales_company;
            if (data.buydate == "Invalid date") {
              this.$Message.warning("保险购买日期不能为空");
              return;
            }
            if (data.validityday == "Invalid date") {
              this.$Message.warning("保险有效期不能为空");
              return;
            }
          }
          this.$post(DeviceAdd(), data).then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.$_bus.$emit("loadUavList", true);
              this.addUav = {
                team_id: "",
                device_name: "",
                brand: "DJI",
                model: [],
                serial_code: "",
                flycontrol: "",
                uav_type: "",
                registration: "",
                is_insurance: "",
                insu_company: "",
                insu_no: "",
                buydate: "",
                validityday: "",
                insutype: "",
                sales_company: "",
              };
              this.addUavModal = false;
              this.$Message.info(res.msg_customer);
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
        }
      });
    },
    cancelAddUavModal() {
      console.log("啊啊啊");
      this.addUav = {
        team_id: "",
        device_name: "",
        brand: "DJI",
        model: "",
        serial_code: "",
        flycontrol: "",
        uav_type: "",
        registration: "",
        is_insurance: "",
        insu_company: "",
        insu_no: "",
        buydate: "",
        validityday: "",
        insutype: "",
        sales_company: "",
      };
    },
  },

  mounted() {
    this.userType = sessionStorage.getItem("userType");
    this.userInfo = JSON.parse(sessionStorage.getItem("user_info"));
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
    if (sessionStorage.getItem("team_id") === null) {
      // if (this.userInfo.permission == 0) {
      this.teamID = this.userInfo.team_id;
      // } else {
      //   this.teamID = -1;
      // }
    } else {
      this.teamID = Number(sessionStorage.getItem("team_id"));
    }
    this.$_bus.$off("change_Team").$on("change_Team", (value) => {
      this.teamID = value;
    });
    this.$post(DeviceType()).then((res) => {
      if (res.code == 1) {
        res.data.forEach((item, index) => {
          item.label = item.brand;
          item.value = index;
          item.children.map((el) => {
            el.label = el.model;
            el.value = el.classId;
          });
        });
        this.UAVTypeList = res.data;
      } else {
        this.$Message.error(res.msg_customer);
      }
    });
  },

  updated() {
    //刷新页面定位到路由指定的nav
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
    [
      { path: "/index/equipment/table/tableOne" },
      { path: "/index/equipment/table/tableTwo" },
      { path: "/index/equipment/table/tableThree" },
      { path: "/index/equipment/table/tableFour" },
      { path: "/index/equipment/table/tableFive" },
      { path: "/index/equipment/table/tableSix" },
    ].forEach((ele) => {
      if (ele.path == activePath) {
        this.Selected(3);
      }
    });
  },
};
</script> 

<style scoped lang="scss">
#equipment {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  background-color: $xf_hei2;
  // display: flex;
  .all_navBar {
    // width: 160px;
    a {
      //   display: flex;
      //   align-items: center;
      padding: 15px 10px;
      height: 50px;
      img {
        margin-right: 11px;
      }
      color: $font_color_1;
    }
    .router-link-active {
      color: #2d8cf0;
    }
  }
  .wrapper {
    // margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.2rem;
    // background-color: $xf_hui1;
    // border-right: 1px solid #000;
    font-size: 14px;
    .route-title {
      // width: 100%;
      height: 30px;
      // background-color: $xf_hui5;
      color: $font_color_1;
      line-height: 30px;
      // padding-left: 20px;
      font-size: 16px;
    }

    .add {
      width: 120px;
      height: 32px;
      line-height: 32px;
      background: #2d8cf0;
      // margin: 10px auto;
      cursor: pointer;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .add:hover {
      background: #57a3f3;
    }
  }
  .conent {
    height: 100%;
    // width: calc(100% - 160px);
    background-color: $xf_hei2;
  }
}
/deep/.addUAV-modal {
  .content {
    padding: 10px 20px;

    .container {
      width: 100%;
      display: flex;
    }
    .left {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
      .UAVimg {
        width: 100%;
        height: 244px;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
          width: 428px;
          height: auto;
        }
      }
    }
    .Divider {
      width: 2px;
      margin: 10px 20px;
      background-color: #f8f8f9;
    }
    .right {
      h1 {
        font-size: 26px;
        margin-bottom: 30px;
      }
      width: 50%;
    }
  }
  .ivu-modal-footer {
    padding: 10px 18px 10px 18px;
  }
}
</style>